import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../containers/layout'
import SEO from '../components/seo'
import Page from '../components/page/job-app-page-es'

export const query = graphql`
  query {
    sanityJobAppEs {
      title
      _rawBody
      _rawBottomFormBody
      hero {
        __typename
        ... on SanityMainImage {
          _key
          _type
          alt
          asset {
            fluid(maxWidth: 1900) {
              ...GatsbySanityImageFluid
            }
          }
        }
        ... on SanitySlideshow {
          _key
          _type
          slides {
            _key
            altText
            slideImage {
              asset {
                fluid(maxWidth: 1900) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityVideo {
          _key
          _type
          videoType
          videoID
        }
      }
      formSettings {
        thankyou
        emailto
        subject
      }
      seoSettings {
                  title
                  description
                  openGraphImage {
                    asset {
                      url
            }
         }
      }
    }
  }
`

const JobApp = ({data: {sanityJobAppEs: page}}) => (
  <Layout hero={page.hero} title={page.title}>
    {page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null} />)}
    <Page
      title={page.title}
      _rawBody={page._rawBody}
      _rawBottomFormBody={page._rawBottomFormBody}
      thankYou={page.formSettings.thankyou}
      emailto={page.formSettings.emailto}
      subject={page.formSettings.subject}
    />
  </Layout>
)

export default JobApp
